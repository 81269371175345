import React from 'react'
    const Contacts = ({ contacts }) => {
      return (
        <div>
          <center><h1>How we can get involved</h1></center>
          {contacts.map((contact) => (
            <div className="card" key={contact.id}>
              <div className="card-body">
                <h5 className="card-title"><a href={contact.website} target='_blank' rel="noopener noreferrer">{contact.name}</a></h5>
                <p className="card-text">{contact.about}</p>
               
                <table cellPadding='5'>
                    <tbody>
                        
                    <tr>
                        <td valign='top'>
                            {
                                contact.donateLink > '' &&
                                <p className="card-text"><a href={contact.donateLink} target='_blank'  rel="noopener noreferrer"><img width="150" src='../donate.png' alt={contact.name}></img></a></p>
                            }
                            {contact.petition > '' &&
                                <p className="card-text"><a href={contact.petition} target='_blank'  rel="noopener noreferrer"><img width="150" src='../petition.png' alt={contact.name}></img></a></p>
                            }

                        
                        
                        </td>

                        
                        
                        

                    {contact.social.map((s) => (
                   <td valign='top' key={s.platform + s.handle}><a rel="noopener noreferrer" target='_blank' href={'http://www.' + s.platform + '.com/' + s.handle}><img width='50' alt={s.platform} src={s.platform + '.png'}></img></a></td>
                ))}
                    </tr>
                    </tbody>
                </table>
                
              </div>
            </div>
          ))}
        </div>
      )
    };
    export default Contacts